import { LoadingView } from "./LoadingView";
import { AuthErrorView } from "./AuthErrorView";
import { useCustomApplicationToken } from "./hooks/useApplicationToken";

export const auth = () => [
  {
    target: "$ONE_AUTH_PUBLIC_VIEW",
    handler: { component: AuthErrorView }
  },
  {
    target: "$ONE_AUTH_LOADING_VIEW",
    handler: { component: LoadingView }
  },
  {
    target: "$ONE_AUTH_ERROR_VIEW",
    handler: { component: AuthErrorView }
  },
  {
    target: "$ONE_AUTH_USE_APPLICATION_TOKEN",
    handler: { hook: useCustomApplicationToken }
  }
];
