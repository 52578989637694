const dictionarySeparator = "-";
const dictionaryLocalePrefix = "dictionaries";
const getPaths = (object, previousPath = "") => {
  if (Array.isArray(object)) {
    return object.map((element) => previousPath + element);
  } else {
    return Object.entries(object).reduce(
      (accumulator, currentEntry) => [
        ...accumulator,
        ...getPaths(currentEntry[1], previousPath + currentEntry[0] + "/")
      ],
      []
    );
  }
};

const getDictionaryValues = (key) => {
  const finalDict = Array.isArray(key)
    ? key.reduce((acc, k) => acc[k], dictionaries)["values"] // Passing an array means the dictionary is an object with a "values" prop at some point (like periods)
    : dictionaries[key];

  return finalDict.map((option) => option.value);
};

const modelDictionaries = {
  180: {
    2021: ["situation", "modality", "numType"]
  },
  190: {
    2021: [
      "childrenCalculation",
      "familySituation",
      "perceptorDisability",
      "contractOrRelationship",
      "perceptionKey",
      "perceptionSubkey"
    ],
    2023: ["ownerLivingUnit", "complementarySupportChildhood"]
  },
  202: {
    2018: ["paymentMethod", "territoryForal"],
    2023: ["typePercent", "territoryForal"]
  },
  347: {
    2021: ["binaryOpt", "inmovableSituation", "numType", "operationKey"]
  },
  349: {
    2022: ["operationKey", "rectificationKey", "rectificationPeriod"]
  },
  vatRecords: {
    2022: [
      "capitalGoodsAmortizationMethod",
      "capitalGoodsGoodType",
      "capitalGoodsGoodUnsubscribeReason",
      "capitalGoodsPeriod",
      "issuedChargeMediumUsed",
      "issuedIncomeConcept",
      "issuedInvoiceType",
      "issuedOperationExempt",
      "issuedOperationKey",
      "issuedOperationQualification",
      "issuedReceivedPeriod",
      "receivedBillType",
      "receivedExpenseConcept",
      "receivedOperationKey",
      "receivedPaymentMean",
      "receivedDeductionPeriodPeriod",
      "code",
      "type"
    ]
  }
};

const dictionaries = [
  "activity",
  "activity2022",
  "countries",
  "europeCountries",
  "provinces",
  "periods",
  "typePercent",
  "europeCountriesWithSpain",
  "roadTypes",
  "nifType",
  "towns",
  "paymentMethods",
  ...getPaths(modelDictionaries)
].reduce((acc, cur) => {
  acc[cur.replaceAll("/", dictionarySeparator)] = require("./" + cur + ".json");
  return acc;
}, {});

module.exports = {
  dictionaries,
  getDictionaryValues,
  dictionaryLocalePrefix,
  dictionarySeparator
};
