export const useBrowserStorage = (key) => {
  const get = async () => JSON.parse(localStorage.getItem(key));

  const set = async (val) => {
    localStorage.setItem(key, JSON.stringify(val));
    return true;
  };

  const clean = () => {
    localStorage.removeItem(key);
    return true;
  };

  return {
    get,
    set,
    clean
  };
};
