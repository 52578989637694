import { useAxios } from "@onefront/react-sdk";

export const useServiceLogin = () => {
  const { axios } = useAxios();

  const validate = async (token) => {
    const res = await axios.post(
      `loginApi://v1/token/refresh`,
      {},
      {
        headers: { "x-refresh-token": token }
      }
    );

    return res.data;
  };

  const introspect = async (token) => {
    const r2 = await axios.post(`loginApi://v1/token/introspect`, {
      applicationToken: token
    });
    return r2.data;
  };

  const verify = async (token) => {
    const result = await axios.post(`loginApi://v1/token/verify`, {
      applicationToken: token
    });
    return result.data;
  };

  const refresh = async () => {
    const res = await axios.post("loginApi://v1/token/refresh", {});
    const { applicationToken } = res.data;
    return applicationToken;
  };

  return {
    validate,
    introspect,
    verify,
    refresh
  };
};
