import oneFront from "@onefront/react-sdk";
import { dashboard } from "./features/dashboard";
import { auth } from "./features/auth";
import { declarations } from "./features/declarations";
import { locale } from "./services/locale";
import { error } from "./services/error";

oneFront
  .run({
    settings: {
      one: {
        auth: {
          token: {
            param: "embeddedAppToken",
            verify: true,
            refresh: true
          }
        },
        rest: {
          header: {
            accessToken: {
              key: "x-access-token",
              value: "{TOKEN}"
            }
          }
        },
        layout: {
          nav: {
            open: false
          },
          showCompanySelector: false,
          appSwitch: false
        },
        login: {
          target: {
            params: ["mode=redirect", "appId=demo"]
          }
        }
      }
    },
    services: [locale, error],
    features: [auth, dashboard, declarations]
  })
  .catch(console.error);
