import useQueryString from "use-query-string";

import { useServiceLogin } from "./useServiceLogin";

export const useDelegatedToken = () => {
  const serviceLogin = useServiceLogin();
  const tokenParam = "embeddedAppToken";

  const [queryString, setQuery] = useQueryString(window.location, (path) => {
    window.history.pushState(null, document.title, path);
  });

  return {
    token: queryString[tokenParam],
    clear: () => setQuery({ ...queryString, [tokenParam]: null }),
    validate: () => serviceLogin.validate(queryString[tokenParam])
  };
};
