import { faLandmarkFlag } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@vapor/react-material/Box";
import List from "@vapor/react-material/List";
import ListItem from "@vapor/react-material/ListItem";
import ListItemIcon from "@vapor/react-material/ListItemIcon";
import ListItemText from "@vapor/react-material/ListItemText";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const NavContent = ({ basePath }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        <ListItem button component={Link} to={basePath}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faLandmarkFlag} color="white" />
          </ListItemIcon>
          <ListItemText primary={t("FiscalDeclarations")} />
        </ListItem>
      </List>
    </Box>
  );
};
