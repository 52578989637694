import { AppTitle } from "./AppTitle";
import { Dashboard } from "./Dashboard";

export const dashboard = () => [
  {
    target: "$ONE_TOOLBAR_CONTENT_LEFT",
    handler: { component: AppTitle }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "/",
      element: <Dashboard />
    }
  }
];
