// Vapor Components
import Card from "@vapor/react-material/Card";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Button from "@vapor/react-material/Button";
// OneFront API
import { useAuth, useTokenData } from "@onefront/react-sdk";
// Language: javascript
import { useTranslation } from "react-i18next";

export const Dashboard = () => {
  const { dropToken } = useAuth();
  const data = useTokenData();
  const { t } = useTranslation();

  return (
    <Card sx={{ m: 3, p: 3 }}>
      <Typography variant="h5">{t("Dashboard")}</Typography>
      <Typography variant="body" component="p">
        {t("Welcome")} {data.name}
      </Typography>
      <pre>{JSON.stringify(data, null, 2)}</pre>
      <Button variant="contained" sx={{ mt: 3 }} onClick={dropToken}>
        {t("Logout")}
      </Button>
    </Card>
  );
};
