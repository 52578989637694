import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { useTokenData } from "@onefront/react-sdk";
import i18n from "../../locales/i18n";

export const LocaleWrapper = ({ children }) => {
  const data = useTokenData();

  useEffect(() => {
    data?.locale && i18n.changeLanguage(data.locale);
  }, [data]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
