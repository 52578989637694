import jwtDecode from "jwt-decode";
import { useDelegatedToken } from "./useDelegatedToken";
import { useServiceLogin } from "./useServiceLogin";
import { useBrowserStorage } from "./useBrowserStorage";

export const useCustomApplicationToken = () => {
  const storageKey = "at";
  const browserStorage = useBrowserStorage(storageKey);
  const loginService = useServiceLogin();
  const delegatedToken = useDelegatedToken();

  /**
   * Persist an ApplicationToken
   * @param String Application Token
   * @returns
   */
  const set = (token) =>
    token ? browserStorage.set(token) : browserStorage.clean();

  /**
   * It retrieves the ApplicationToken by:
   * - validating a Delegated Token against the Authorization Authority
   * - retrieving the previously persisted Application Token
   *
   * @returns String ApplicationToken
   */
  const get = async () => {
    // Attempt to load an existing AccessToken from the storage
    if (!delegatedToken.token) {
      const token = await browserStorage.get();
      if (!token) {
        // Call the login service to get a new token
        // (if the secure cookie with the reviso grant token is set, a new app token will be generated)
        const newToken = await loginService.refresh();
        set(newToken);
        return { applicationToken: newToken };
      }
      return { applicationToken: token };
    }

    // Remove token from url as soon as possible
    delegatedToken.clear();

    // Refresh a DelegateToken
    const { applicationToken } = await delegatedToken.validate();

    // Persist the token and return it
    set(applicationToken);
    return { applicationToken };
  };

  /**
   * Uses JWT wizardry to validate the ApplicationToken locally.
   * It should throw if the token not valid.
   *
   * @param String ApplicationToken
   * @returns Object Contents of the ApplicationToken
   */
  const introspect = async (token) => {
    const tokenData = jwtDecode(token);
    const now = Date.now() / 1000;
    // Honor EXP
    if (tokenData.exp && tokenData.exp - now < 0) {
      throw new Error("JWT Token Expired");
    }
    // Honor NBF
    if (tokenData.nbf && tokenData.nbf - now > 0) {
      throw new Error("JWT Token invalid NotBefore");
    }
    return { token, tokenData };
  };

  /**
   * It validates the current session agains the AuthorizationAuthority
   * using backend APIs.
   *
   * @param String ApplicationToken
   * @returns Object Contents of the ApplicationToken
   */
  const verify = async (token) => {
    const tokenData = await loginService.verify(token);
    return { token, tokenData };
  };

  /**
   * @returns String Application Token (new)
   */
  const refresh = async () => {
    const token = await loginService.refresh();
    const tokenData = await loginService.introspect(token);

    set(token);

    return {
      token,
      tokenData
    };
  };

  return {
    get,
    set,
    introspect,
    verify,
    refresh
  };
};
