import { lazy, Suspense } from "react";

import { NavContent } from "./NavContent";
import { Loading } from "../../components/Loading";

const DeclarationPage = lazy(() => import("../DeclarationPage"));
const ModelPage = lazy(() => import("../ModelPage"));
const HomePage = lazy(() => import("../Homepage"));
const CreateNewDeclaration = lazy(() => import("./CreateNewDeclaration"));

export const declarations = () => [
  {
    target: "$REACT_ROOT_WRAPPER",
    handler: {
      component: ({ children }) => (
        <Suspense fallback={<Loading />}>{children}</Suspense>
      )
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "/declarations/",
      element: <HomePage />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "/declarations/models/:modelId",
      element: <ModelPage />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "/declarations/model/:declarationId",
      element: <DeclarationPage />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      path: "/declarations/create-new",
      element: <CreateNewDeclaration />
    }
  },
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: () => <NavContent basePath={"/declarations"} />
    }
  }
];
