import Box from "@vapor/react-material/Box";
import CircularProgress from "@vapor/react-material/CircularProgress";
import Typography from "@vapor/react-extended/ExtendedTypography";

export const Loading = ({ message }) => {
  return (
    <Box
      py={5}
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress />
      {message && (
        <Typography ml={2} variant="h5">
          {message}
        </Typography>
      )}
    </Box>
  );
};
