import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import common_en from "./en/common.json";
import common_es from "./es/common.json";
import common_it from "./it/common.json";
import dictionaries_en from "@fda/models/locale/en/dictionaries.json";
import dictionaries_es from "@fda/models/locale/es/dictionaries.json";
import dictionaries_it from "@fda/models/locale/it/dictionaries.json";
import { dictionaryLocalePrefix } from "@fda/models/utils/dictionaries";

i18n.use(LanguageDetector).init({
  fallbackLng: "es",
  debug: false,
  react: {
    useSuspense: true,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default"
  },
  resources: {
    en: {
      translation: { ...common_en, [dictionaryLocalePrefix]: dictionaries_en }
    },
    es: {
      translation: { ...common_es, [dictionaryLocalePrefix]: dictionaries_es }
    },
    it: {
      translation: { ...common_it, [dictionaryLocalePrefix]: dictionaries_it }
    }
  }
});

export default i18n;
