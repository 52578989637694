import React from "react";
import Button from "@vapor/react-material/Button";
import Container from "@vapor/react-material/Container";
import IconButton from "@vapor/react-material/IconButton";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { faList, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Container
          align="center"
          sx="
        width:420px;
        height:232px;  
        margin-bottom:40px;
      "
        >
          <IconButton
            sx="font-size: 100.8px; 
              color: #E4EAF0; 
              letter-spacing: 0; 
              text-align: center; 
              line-height: 100.8px; 
              font-family: Font Awesome 5 Pro; 
              font-weight: 900;"
          >
            <FontAwesomeIcon icon={faList} />
          </IconButton>
          <IconButton
            sx="font-size: 48px; 
              color: #005075; 
              letter-spacing: 0; 
              text-align: center; 
              line-height: 48px; 
              font-family: Font Awesome 5 Pro; 
              font-weight: 900;
              margin: 70px 0 0 10px; "
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </IconButton>
          <Typography
            variant="h1"
            sx="font-size: 24px; 
              color: #005075; 
              letter-spacing: 0.18px; 
              text-align: center; 
              line-height: 32px; 
              font-family: Cairo; 
              font-weight: 600;
              margin-top: 10px;"
          >
            Title error
          </Typography>
          <Typography
            variant="h1"
            sx="font-size: 14px; 
            color: #152935; 
            letter-spacing: 0.25px; 
            text-align: center; 
            line-height: 20px; 
            font-family: Roboto; 
            font-weight: 400; 
            margin: 10px 0 30px;"
          >
            {this.state.error.toString()}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              this.setState({ error: null, errorInfo: null });
            }}
          >
            {this.props.t("BackToThePage")}
          </Button>
        </Container>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
